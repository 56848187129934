<template>
  <div class="practiceExam">
    <div class="content">
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <Breadcrumb></Breadcrumb>
        <div class="contDown" v-show="!noTime">
              <div>剩余时间：</div>
              <CountDown
                class="time"
                ref="CountDown"
                :infos="timeInfos"
                @overTime="overTime"
                @timeStamp="handleTimeStamp"
              >
              </CountDown>
            </div>
      </div>
      <div class="box">
        <div class="content-left">
          <!-- <div class="course-title">
            <div style="width:80%;">
              <div class="name" v-if="detailList.info">{{ detailList.info.title }}</div>
              <div class="detailed">
                <!== <span>题目提供者：{{detailList.info.user_real_name}}</span>
                <span>难度：{{detailList.info.diff_title}}</span>
                <span>提交：{{detailList.info.log_num}}</span>
                <span>通过：{{detailList.info.jige_total}}</span> ==>
                <!== <span v-if="examType=='比赛'&&detailList.type_title!='作业'">比赛时间：{{detailList.start_time_f}}</span> ==>
              </div>
            </div>
            <div class="edit">
              <!== <div class="feedback" @click="handleCommand('feedback' , detailList.title , '天梯计划')">
                <i class="el-icon-edit-outline"></i>反馈
              </div> ==>
              <div class="button-primary" :style="{ padding:'4px 20px'}" @click="submit">
                {{ headrBtn }}
              </div>
            </div>
          </div> -->
          <!-- //题目列表 -->
          <!-- <TopicSum
            v-loading="loading"
            ref="TopicSum"
            :element-loading-text="loadingText"
            :discussShow="discussShow"
            :status="statusTopic"
            :detailList="detailList"
            :deploy="deploy"
            :noTime="noTime"
            :duration="duration"
            @submit="submit"
            @setPitchNums="setPitchNums"
            @setAnswers="setAnswers"
          ></TopicSum> -->
           <!-- 混合题上下题切换 -->
           <newTopicSum
              v-loading="loading"
              ref="TopicSum"
              :element-loading-text="loadingText"
              :discussShow="discussShow"
              :status="statusTopic"
              :detailList="detailList"
              :deploy="deploy"
              :noTime="noTime"
              :duration="duration"
              @setAnswers="setAnswers"
              @submit="submit"
              @setPitchNums="pitchNums = $event"
            ></newTopicSum>

        </div>
        <!-- <div class="content-right">
          <div class="">
            <div class="contDown" v-show="!noTime">
              <div>剩余时间：</div>
              <CountDown
                class="time"
                ref="CountDown"
                :infos="timeInfos"
                @overTime="overTime"
                @timeStamp="handleTimeStamp"
              >
              </CountDown>
            </div>
            <div style="width: 100%">
              <TopicList
                @topicClick="topicClick"
                :topicParam="topicParam"
                :pitchNums="pitchNums"
                :show="false"
              ></TopicList>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <SubmitPop
      ref="SubmitPop"
      @confirmPop="confirmPop"
      @cancelPop="cancelPop"
      @closePop="cancelPop"
    >
      <div
        v-if="pitchNums.length < topicParam.topicList.length"
        style="color: rgba(233, 75, 50, 1)"
      >
        <!-- 你还有试题没有做完，确定提交吗？ -->
        请确认所有题目都已作答，是否提交？
      </div>
      <div v-else>确定提交吗？</div>
    </SubmitPop>
    <SubmitPop
      ref="SubmitPopExit"
      @confirmPop="confirmPop"
      @cancelPop="cancelPop"
      @closePop="cancelPop"
      confirmButtonText="提交答案"
      cancelButtonText="离开页面"
    >
      <div style="color: rgba(233, 75, 50, 1)">{{ returnVal }}</div>
    </SubmitPop>
    <el-backtop :bottom="100" :right="400" :visibility-height="400">
      <div class="go-top">
        <i class="el-icon-caret-top" style="color: #00957e"></i>
        <div style="margin-top: -8px">顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
// 题目列表和知识点标签
import TopicList from "@/components/TopicList/TopicList.vue";
import CountDown from "@/components/CountDown/CountDown.vue";
import SubmitPop from "@/components/SubmitPop/SubmitPop.vue";
import newTopicSum from "@/components/TopicSum/newTopicSum.vue";

import { bisaidetail, bisaianswercache } from "@/api/problemList.js";
import { kstdFast, kstdAnswer, dtDanswer } from "@/api/ladder.js";
import { question_log_answer, detail } from "@/api/topic.js";
import TopicSum from "@/components/TopicSum/TopicSum.vue";
import { Base64 } from "js-base64";
import { formateTimeStamp } from "@/components/CountDown/tools.js";
import store from "@/store";
var _ = require("lodash");

export default {
  components: {
    TopicList,
    TopicSum,
    CountDown,
    SubmitPop,
    newTopicSum
  },
  data() {
    return {
      deploy: { isAnswer: false },
      loading: false,
      timeInfos: [0, 0],
      detailList: {},
      topicParam: {
        topicList: [],
        knowledgeTag: [],
      },
      pitchNums: [],
      answers: [],
      OldAnswers: {},
      start_time: 0,
      id: "", //关卡id
      quest_id: "", //单题id
      statusTopic: "preview",
      loadingText: "",
      headrBtn: "提交",
      CurrentDuration: 0,
      log_id: "",
      noTime: false,
      duration:0,//是否有时长
      examTypeC: "list",
      examType: "",
      examTypes: ["比赛", "作业", "考试"],
      discussShow: false,
      questionsNum: 0, //题目总数
      cancelButtonText: false,
      is_log_answer: false, //是否为查看答题详情模式
      returnVal: null,
      leftKey: "kjihgJIKHDCBAGFEdefcab",
      rightKey: "ABCDEFGHIJKabcdefghijk",
      userAuth: {
        user_id: localStorage.getItem("user_id"),
        user_token: localStorage.getItem("user_token"),
      },
    };
  },
  beforeRouteLeave: function (to, from, next) {
    this.handleBeforeRouteLeave(next, to, from);
  },
  mounted() {
    let query = this.$route.query;
    this.id = query.id;
    this.quest_id = query.quest_id || "";
    this.getDetailList();
    this.duration=query.duration
    console.log(this.duration,"this.durationthis.duration")
    this.noTime = parseInt(query.noTime) === 1;
    // console.log(this.noTime,"this.noTime")
    window.addEventListener("beforeunload", this.beforeUnloadHandler, false);
    store.state.loginOutCallback = () => {
      this.LocalStorageCurrent(true);
      this.LocalStorageCurrent(0);
    };
    return;

    this.examType = query.type || "题单";
    this.log_id = query.log_id;
    //是否显示倒计时
    this.noTime = parseInt(query.noTime) === 1;
    this.loading = true;
    if (this.examTypes.indexOf(this.examType) > -1) {
      this.examTypeC = "other";
      if (this.$route.path.indexOf("/match/") !== 0) return this.$routerGo(-1);
      this.getBisaidetail();
    } else {
      this.examTypeC = "list";
      if (this.$route.path.indexOf("/problemList/") !== 0)
        return this.$routerGo(-1);
      // 是否为查看答题详情状态
      if (query.is_log_answer == 1) {
        this.is_log_answer = true;
        this.log_answer(query.log_id);
      }
      this.getDetailList();
    }
    window.addEventListener("beforeunload", (e) => {
      return this.handleBeforeRouteLeave(null, true);
    });
    store.state.loginOutCallback = () => {
      this.LocalStorageCurrent(true);
      this.LocalStorageCurrent(0);
    };
  },
  beforeDestroy() {
    this.handleBeforeRouteLeave(null, true);
  },
  methods: {
    handleBeforeRouteLeave(next, to) {
      var msg;
      if (
        localStorage.getItem("user_id") > 0 &&
        !this.log_id &&
        this.headrBtn === "提交" &&
        this.cancelButtonText
      ) {
        var wzNum =
          this.topicParam.topicList.length -
          this.pitchNums.filter((d) => {
            return !(d.my_answer instanceof Array
              ? d.my_answer.join()
              : d.my_answer);
          }).length;
          console.log(wzNum,"未做题目数量")
        if (this.detailList.duration > 0 && !this.noTime) {
          if (this.$refs.CountDown) this.$refs.CountDown.suspend = true;
          if (this.CurrentDuration > 10) {
            //msg = wzNum > 0 ? "你的题还未做完，确定要离开吗？" : "你的题已做完，是否提交答案？";
            msg = "你的题还没提交，确定要离开吗？";
          } else if (this.CurrentDuration > 0) {
            msg = "只剩下最后" + this.CurrentDuration + "秒，确定要离开吗？";
          }
        } else if (["比赛", "考试"].indexOf(this.examType) > -1) {
          msg = "退出后不可再答题了，确定要离开吗?";
        } else {
          msg =
            wzNum > 0
              ? "你的题还未做完，确定要离开吗？"
              : "你的题已做完，是否提交答案？";
          //msg = '你的题还没提交，确定要离开吗？';
        }
      }
      this.returnVal = msg;
      if (msg) {
        console.log('传true')
        this.LocalStorageCurrent(true);
        next && next(false);
        if (to !== true) {
          this.$refs.SubmitPopExit.show = true;
          this.$refs.SubmitPopExit.cancel = () => {
            next && next(true);
          };
        }
      } else {
        next && next();
        this.LocalStorageCurrent(false);
        console.log('离开当前页')
      }
      return msg;
    },
    handleTimeStamp(time) {
      // console.log(time,"time````````")
      this.CurrentDuration = time;
      this.endConfirmPop();
    },
    endConfirmPop(yztmsg, wztmsg) {
      var is_end = false;
      var yzNum = this.pitchNums.filter((d) => {
          return !(d.my_answer instanceof Array
            ? d.my_answer.join()
            : d.my_answer);
        }).length,
        wzNum = this.topicParam.topicList.length - yzNum;
      if (
        localStorage.getItem("user_id") > 0 &&
        (this.detailList.end_time || 0) > 0 &&
        this.detailList.end_time < new Date().getTime() / 1000
      ) {
        this.$refs.CountDown.suspend = true;
        if (yzNum > 0) {
          yztmsg =
            this.examType +
            "【" +
            this.detailList.title +
            "】结束时间为" +
            this.detailList.end_time_f +
            "，是否提交答案？";
        } else {
          wztmsg =
            this.examType +
            "【" +
            this.detailList.title +
            "】结束时间为" +
            this.detailList.end_time_f +
            "，你来迟了哦~";
        }
        is_end = true;
      }
      if (yzNum > 0 && yztmsg) {
        this.$confirm(yztmsg, "提示", {
          confirmButtonText: "提交答案",
          cancelButtonText: "离开页面",
          closeOnClickModal: false,
          type: "warning",
        })
          .then(() => {
            this.confirmPop();
          })
          .catch(() => {
            this.$confirm(
              "离开页面后，答题信息将删除，是否离开页面？",
              "提示",
              {
                confirmButtonText: "提交答案",
                cancelButtonText: "离开页面",
                closeOnClickModal: false,
                type: "warning",
              }
            )
              .then(() => {
                this.confirmPop();
              })
              .catch(() => {
                this.LocalStorageCurrent(false);
                // this.$routerGo(-1);
                window.close()

              });
          });
      } else if (wztmsg) {
        this.$confirm(wztmsg, "提示", {
          showClose: false,
          cancelButtonText: "离开页面",
          closeOnClickModal: false,
          showConfirmButton: false,
          type: "error",
        })
          .then(() => {
            this.LocalStorageCurrent(false);
            // this.$routerGo(-1);
          window.close()

          })
          .catch(() => {
            this.LocalStorageCurrent(false);
            // this.$routerGo(-1);
          window.close()

          });
      }
      return is_end;
    },
    LocalStorageCurrent: async function (t) {
      if (this.noTime) {
        return;
      }
      var answer_type = this.$route.query.answer_type || "";
      var currentTime = new Date().getTime();
      var duration = this.CurrentDuration || this.detailList.duration || 0;
      var obj,
        OldAnswers = {};
      var key =
        "dati-" +
        Base64.encode(
          answer_type +
            "" +
            (this.examType || "o") +
            "::" +
            this.id +
            ":" +
            localStorage.getItem("user_id")
        );
      if (t === true) {
        if (
          localStorage.getItem("user_id") > 0 &&
          this.headrBtn === "提交" &&
          this.cancelButtonText &&
          !this.log_id
        ) {
          if (this.detailList.duration > 0 && !this.noTime) {
            var start_time =
              this.start_time !== undefined
                ? this.start_time
                : (currentTime / 1000 - this.CurrentDuration) * 1000;
            obj = {
              start_time: start_time,
              duration: this.CurrentDuration, //剩余时间（秒）
              answers: this.answers,
            };
            localStorage.setItem(
              key,
              this.$tool.strtr(
                Base64.encode(JSON.stringify(obj)),
                this.leftKey,
                this.rightKey
              )
            );
            var _formData = Object.assign({}, this.userAuth, {
              id: this.id,
              start_time: start_time,
              currentTime: currentTime,
              answers: this.answers,
              answer_type: this.$route.query.answer_type || "",
              duration: this.CurrentDuration, //剩余时间（秒）
              type: this.examTypeC,

            });
            await bisaianswercache(_formData);
          }
        }
      } else if (t === false || t === 0) {
        localStorage.removeItem(key);
        if (t === false) {
          await bisaianswercache(
            Object.assign(
              {
                id: this.id,
                type: this.examTypeC,
                isGet: -1,
              },
              this.userAuth
            )
          );
        }
        this.userAuth = {};
      } else {
        // this.timeInfos = [this.detailList.duration, 0];
        //   console.log(this.timeInfos,"this.timeInfos111")


        obj = localStorage.getItem(key);
        if (obj) obj = this.$tool.strtr(obj, this.rightKey, this.leftKey);
        var res = await bisaianswercache({
          id: this.id,
          type: this.examTypeC,
          isGet: 1,
        });
        var data = (res.data || {}).data;
        if (data) {
          obj = this.$tool.strtr(data, this.leftKey, this.rightKey);
          // console.log(obj,"111111111")
        }
        obj = obj ? Base64.decode(obj) : "";
        // console.log(obj,"obj1111")
        if (obj) {
          // this.timeInfos = [this.detailList.duration, 0];
          // console.log(this.timeInfos,"this.timeInfos111")
          obj = JSON.parse(obj);
          if (obj) {
            // console.log(obj,"parse后")
            this.start_time = parseInt(obj.start_time);
            var _duration = parseInt((currentTime - this.start_time) / 1000);
            // console.log(_duration,"_duration_duration")
            // if (duration > 0) {
            //   this.CurrentDuration = _duration > 0 ? _duration : 0;
            //   this.timeInfos = [this.CurrentDuration, 0];
            //   try {
            //     this.$refs.CountDown.timeInfos = this.timeInfos;
            //   } catch (e) {}
            //   this.$refs.CountDown.mistiming();
            // }
            // if (_duration > 0) {
            //   _duration = Math.max(this.detailList.duration - _duration, 0);
            //   this.CurrentDuration = _duration;
            //   console.log(this.CurrentDuration,":this.CurrentDuration111")
            // } else {
              this.CurrentDuration = obj.duration || duration;
              // console.log(this.CurrentDuration,":this.CurrentDuration222")

            // }
            this.timeInfos = [this.CurrentDuration, 0];
            // console.log(this.timeInfos,"this.timeInfos222")
            for (var i = 0; i < (obj.answers || []).length; i++) {
              OldAnswers[obj.answers[i].quest_id] = obj.answers[i];
            }
            this.OldAnswers = OldAnswers;
            if (this.CurrentDuration === 0) {
              // return this.endConfirmPop(this.examType + "【" + this.detailList.title +"】时长为" + formateTimeStamp(duration) + "，是否提交答案？",
              //   this.examType +
              //     "【" +
              //     this.detailList.title +
              //     "】答题时间已结束!"
              // );
              return this.endConfirmPop('',"答题时间已结束!");
            }
            try {
              this.$refs.TopicSum.init({ OldAnswers: OldAnswers });
            } catch (e) {}
          } else {
            this.timeInfos = [this.detailList.duration, 0];
            console.log(this.timeInfos,"this.timeInfos111")

          }
          try {
            this.$refs.CountDown.timeInfos = this.timeInfos;
          } catch (e) {}
        }
        this.$refs.CountDown.mistiming();
      }
    },
    getBisaidetail() {
      bisaidetail({ id: this.id })
        .then((res) => {
          let info = res.data.info;
          console.log(info,"不知道什么接口")
          this.timeInfos[0] = info.duration;
          this.detailList = {
            type_title: info.type_title,
            diff_title: info.diff_title,
            user_real_name: info.user_name,
            title: info.title,
            start_time_f: info.start_time_f,
            end_time_f: info.end_time_f,
            start_time: info.start_time,
            end_time: info.end_time,
            log_num: info.count,
            jige_total: info.jige,
            duration: info.duration,
            questions_list: res.data.questions,
          };
          var is_end = this.endConfirmPop();
          this.questionsNum = res.data.questions.length;
          this.topicParam = {
            topicList: [...info.questions],
            knowledgeTag: info.knows,
          };
          //如果答过题，就显示答题结果
          if (info.log_id) {
            this.log_id = info.log_id;
            this.log_answer(info.log_id);
          } else if (!is_end) {
            this.confirmAnswer();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmAnswer() {
      this.$confirm("是否确认开始答题?", "提示", {
        confirmButtonText: "开始答题",
        cancelButtonText: "离开页面",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.cancelButtonText = true;
          this.start_time = new Date().getTime();
          this.LocalStorageCurrent();
        })
        .catch(() => {
          window.close()
        });
    },
    getDetailList() {
      let api = this.quest_id ? detail : kstdFast;
      // console.log(api, "apiiiiiiii");
      api({ id: this.quest_id ? this.quest_id : this.id })
        .then((res) => {
          // if((res.data.type_title || '').toString() ==='作业'){
          //   this.noTime = true;
          // }
          this.detailList = res.data;
          this.$set(this.detailList,'duration',res.data.info.duration)
          // this.detailList.duration=res.data.info.duration
          this.$set(this.detailList,'questions_notype_list',this.detailList.questions_notype)
          // console.log(this.detailList,"this.detailListthis.detailList")
          this.$store.state.answerCorrect = _.cloneDeep(
            res.data.questions_list
          );
          this.questionsNum = res.data.questions_list.length;
          this.topicParam = {
            topicList: [...res.data.questions],
            knowledgeTag: [],
          };
          // console.log(this.topicParam, "ooo");
          
          if (!this.noTime && !this.is_log_answer) {
            this.confirmAnswer()
          }else{
            this.cancelButtonText = true;
          }
        })
        .catch((err) => {
          console.log(err, "errrrrrrrr");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    submit() {
      this.$refs.SubmitPop.show = true;
      this.$refs.CountDown.suspend = true;
    },
    cancelPop() {
      this.$refs.SubmitPop.show = false;
      this.$refs.SubmitPopExit.show = false;
      this.$refs.CountDown.suspend = false;
    },
    //确认提交
    confirmPop() {
      this.$refs.SubmitPop.show = false;
      this.$refs.SubmitPopExit.show = false;

      this.loading = true;
      this.loadingText = "提交中...";

      //   let api = otherAnswer
      //   if (this.examTypes.indexOf(this.examType) > -1) {
      //     api = bisaianswer
      //   }
      let api = this.quest_id ? dtDanswer : kstdAnswer;
      let data = {
        id: this.id,
        start_time: this.start_time / 1000,
        last_commit_time: new Date().getTime() / 1000,
        answers: this.answers,
      };
      if (this.quest_id) {
        data.quest_id = this.quest_id;
      }
      if (!this.quest_id) {
        data.questKey = this.detailList.key;
      }

      api(data)
        .then((res) => {
          this.LocalStorageCurrent(false);
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
          });
          // this.cancelButtonText = true;
          this.log_id = res.data.id;
          this.$router.push(
            "/ladder/stage?id=" + this.id + "&log_id=" + this.log_id
          );
        })
        .finally(() => {
          this.loadingText = "";
          this.loading = false;
        });
    },
    //查看详情状态
    log_answer(id) {
      question_log_answer({ log_id: id })
        .then((res) => {
          this.deploy.isAnswer = true;
          this.discussShow = true;
          let data = Object.values(res.data.log_list);

          this.$store.state.answerData = {
            answer: res.data.log_list,
          };
          // this.$store.state.answerCorrect = _.cloneDeep(res.data.questions_list)
          this.statusTopic = "answer";
          this.loading = false;
          this.loadingText = "";
          this.headrBtn = "查看结果";
          let pitchNums = [];
          data.map((v) => {
            if (!!v.answer) {
              pitchNums.push(v.question_id);
            }
          });
          this.setPitchNums(pitchNums);
        })
        .finally(() => {
          this.loadingText = "";
          this.loading = false;
        });
    },
    //滚动到点击的题目
    topicClick(selector) {
      document.getElementById(selector).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
    //右边题目高亮
    setPitchNums(pitchNums) {
      this.pitchNums = pitchNums;
    },
    setAnswers(answers) {
      this.answers = answers;
    },
    //答题时间结束
    overTime(str) {
      if (this.headrBtn == "查看结果") {
        return;
      }
      if (str) {
        this.$message({
          message: str,
          type: "warning",
        });
      } else {
        this.$message({
          message: "答题时间已结束",
          type: "warning",
        });
        this.confirmPop();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.practiceExam {
  ::v-deep {
    .el-backtop {
      width: 56px;
      height: 56px;
    }
  }
  .contDown {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        color: #303030;
        // position: fixed;
        // width: 345px;
        // margin-bottom: 40px;
        .time {
          text-align: center;
          font-size: 30px;
          font-weight: 600;
        }
      }
  .go-top {
    .el-icon-caret-top {
      font-size: 26px;
      margin-top: -6px;
    }
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }
  .box {
    display: flex;
    justify-content: space-between;
    .content-left {
      // width: 70%;
      width: 100%;
      .course-title {
        padding: 2rem;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #dbdbdb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .edit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .el-icon-edit-outline {
            font-size: 20px;
            margin-right: 0.2rem;
          }
        }
        .name {
          font-size: 21px;
          color: #0a0a0a;
          line-height: 29px;
          margin-bottom: 1rem;
        }
        .detailed {
          font-size: 14px;
          color: #0a0a0a;
          line-height: 20px;
          > span {
            margin-right: 1.5rem;
          }
        }
      }
      .operation {
        margin-left: 1rem;
      }
    }
    .content-right {
      position: relative;
      // position: fixed;
      width: 27%;
      // right: 0;
      .fixed {
        position: fixed;
        width: 350px;
        height: 76vh;
        overflow-y: auto;
        background-color: #f5f5f5;
      }
     
      .recommend {
        margin-top: 44px;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        padding: 1rem;
        font-size: 14px;
      }
    }
  }
}
</style>
